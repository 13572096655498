import _debounce from 'lodash/debounce'

export default class Like {
  constructor(classname, sns_animation = false, rootElement = document) {
    this.sns_animation = sns_animation
    this.classname = classname
    this.$buttons = rootElement.querySelectorAll(this.classname)
    this.hide_event = null
    this.long_touch_event = null

    if (this.$buttons.length < 1) return

    this.$buttons.forEach((elem, n) => this.clickEvent(elem))
    this.$buttons.forEach((elem, n) => this.hoverEvent(elem))
    this.$buttons.forEach((elem, n) => this.touchEvent(elem))
  }

  animationend(e) {
    setTimeout(() => {
      e.target.classList.add('like-anime-hidden')
      e.target.classList.remove('like-animation')
    }, 500)
  }

  hoverEvent(elem) {
    const reactionPopup = document.querySelector(
      `${this.classname}-popup[data-value="${elem.dataset.value}"]`
    )
    if (!reactionPopup) return

    elem.addEventListener(
      'mouseout',
      () => {
        this.popupHide(reactionPopup)
      }
    )
    elem.addEventListener(
      'mouseover',
      () => {
        this.popupShowWithTimer(reactionPopup)
        reactionPopup.addEventListener('mouseover', () => {
          this.popupShow(reactionPopup)
        })
        reactionPopup.addEventListener('mouseout', () => {
          this.popupHide(reactionPopup)
        })
      }
    )
  }
  clickEvent(elem) {
    const eyecatch = document.querySelector(
      `${this.classname}-animation[data-value="${elem.dataset.value}"]`
    )
    const reactionPopup = document.querySelector(
      `${this.classname}-popup[data-value="${elem.dataset.value}"]`
    )

    if (this.sns_animation === true) {
      eyecatch.removeEventListener('animationend', this.animationend)
      eyecatch.addEventListener('animationend', this.animationend)
    }

    elem.addEventListener(
      'click',
      _debounce(() => {
        if (!elem.dataset.method) return
        // 連打対策
        elem.disabled = !elem.disabled;
        if (elem.dataset.method === 'post' && this.sns_animation === true) {
          eyecatch.classList.remove('like-anime-hidden')
          eyecatch.classList.add('like-animation')
        }
        if (reactionPopup){
          reactionPopup.classList.add('popup_hide')
        }
        return this.formAction(elem)
      }, 400)
    )
  }

  async formAction({ dataset }) {
    let reactionParam = ''
    if (dataset.reaction != null){
      reactionParam = `&like_reaction_attributes[community_reaction_id]=${dataset.reaction}`
    }
    const response = await fetch(
      `${dataset.url}?${dataset.key}_id=${dataset.value}${reactionParam}`,
      {
        method: dataset.method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document
            .querySelector('meta[name=csrf-token]')
            .getAttribute('content'),
        },
      }
    )

    const text = await response.text()
    // $.ajaxのevalの代替しで`XXX.js.haml`の処理を実施
    await Function(`"use strict";return (${text})`)()

    // 上記`XXX.js.haml`で生成されたDOMにEventを付与
    const replacedButton = document.querySelector(
      `${this.classname}[data-key="${dataset.key}"][data-value="${dataset.value}"]`
    )

    replacedButton.classList.add(
      dataset.method === 'delete' ? '-unfilled' : '-filled'
    )

    this.clickEvent(replacedButton)
    this.hoverEvent(replacedButton)
    this.touchEvent(replacedButton)
    const reactionPopup = document.querySelector(
      `${this.classname}-popup[data-value="${dataset.value}"]`
    )
    if (!reactionPopup) return
    const replacedButtons = reactionPopup.querySelectorAll(
      `${this.classname}[data-key="${dataset.key}"][data-value="${dataset.value}"]`
    )
    replacedButtons.forEach((elem, n) => { this.clickEvent(elem) })

  }

  isLessMD() {
    if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
      return true;
    } else {
      return false;
    }
  }

  touchEvent(elem) {
    if (!this.isLessMD()){ return; }

    const reactionPopup = document.querySelector(
      `${this.classname}-popup[data-value="${elem.dataset.value}"]`
    )
    if (!reactionPopup) return

    elem.addEventListener(
      'touchstart',
      (e) => {
        e.preventDefault()
        this.long_touch_event = setTimeout(() => {
          this.popupShowWithTimer(reactionPopup,5000)
          this.long_touch_event = null
        }, 500)
      }
    )
    elem.addEventListener(
      'touchend',
      (e) => {
        if (this.long_touch_event){
          clearTimeout(this.long_touch_event)
          e.target.click()
        }
        const reactionButtons = document.querySelectorAll(
          `.c-Button-reaction[data-value="${elem.dataset.value}"]`
        )
        reactionButtons.forEach((elem, n) => { 
          if (elem.classList.contains('hovered')){
            elem.click()
            elem.classList.remove('hovered')
            return;
          }
        })
        this.popupHide(reactionPopup)
      }
    )
    elem.addEventListener(
      'touchmove',
      (e) => {
        const touch = e.touches[0];
        const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
        const reactionButtons = document.querySelectorAll(
          `.c-Button-reaction[data-value="${elem.dataset.value}"]`
        )
        reactionButtons.forEach((elem, n) => { 
          if (elem === targetElement.closest('.c-Button-reaction')){
            elem.classList.add('hovered')
          }else{
            elem.classList.remove('hovered')
          }
        })
      }
    )
  }

  popupShowWithTimer(popupElem,duration = 1000) {
    popupElem.classList.remove('popup_hide')
    this.popupHide(popupElem,duration)
  }

  popupShow(popupElem) {
    if (this.hide_event){
      clearTimeout(this.hide_event)
    }
    popupElem.classList.remove('popup_hide')
  }

  popupHide(popupElem,duration = 1000) {
    if (this.hide_event){
      clearTimeout(this.hide_event)
    }
    this.hide_event = setTimeout(() => {
      popupElem.classList.add('popup_hide')
    }, duration)
  }
}
