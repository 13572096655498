class BlockCustomHtml extends HTMLElement {
  constructor() {
    super();
    const templateId = this.getAttribute('template-id');
    const template = document.getElementById(templateId).content.cloneNode(true);

    const shadow = this.attachShadow({ mode: 'open' });
    shadow.appendChild(template);
  }
}

export { BlockCustomHtml };
