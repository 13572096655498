import videoUrlParser from 'js-video-url-parser';
import YouTubeExtend from '../../javascripts/js-video-url-parser/provider/youtube';
import { fetchWithTimeout } from '../api_utils';
videoUrlParser.plugins.youtube = new YouTubeExtend();

class ViewMode {
  constructor($element) {
    this.$element = $element;
  }

  replace() {
    document.querySelectorAll('oembed[url]').forEach(async elem => {
      const videoInfo = videoUrlParser.parse(elem.attributes.url.value);

      if (videoInfo) {
        switch (videoInfo.provider) {
          case 'vimeo':
            elem.parentElement.innerHTML = this.createVideoBlock(`//player.vimeo.com/video/${videoInfo.id}`);
            break;
          case 'youtube':
            elem.parentElement.innerHTML = this.createVideoBlock(`//www.youtube.com/embed/${videoInfo.id}`);
            break;
        }
      }
      else if (elem.attributes.url.value) {
        const card = await this.createOGCard(elem.attributes.url.value);
        elem.parentElement.innerHTML = card;
      }
    });
  }

  createVideoBlock(url) {
    const videoContainer = document.createElement('div');
    const iframe = document.createElement('iframe');
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('src', url);
    iframe.setAttribute('frameborder', 0);
    iframe.setAttribute('allowfullscreen', '');
    videoContainer.className = 'video-container';
    videoContainer.appendChild(iframe);
    return videoContainer.outerHTML;
  }

  async createOGCard(request_url) {
    const url = encodeURIComponent(request_url);
    const response = await fetchWithTimeout(`/open_graph?url=${url}`);
    const json = await response.json();
    return json.html;
  }
}

export default ViewMode;
