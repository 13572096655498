import YouTube from 'js-video-url-parser/lib/provider/youtube';

const YouTubeExtend = function () {
  YouTube.call(this);
};
YouTubeExtend.prototype = Object.create(YouTube.prototype);
YouTubeExtend.prototype.parseVideoUrl = function (url) {
  const regex =
    /(?:(?:v|vi|be|videos|embed|shorts)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i;
  const match = url.match(regex);
  return match ? match[1] : undefined;
};
YouTubeExtend.prototype.constructor = YouTubeExtend;

export default YouTubeExtend;
