import KeenSlider from 'keen-slider';
import { BlockCustomHtml } from '../../../javascripts/components/BlockCustomHtml';
import Like from '../../../javascripts/components/Like';
import ViewMode from '../../../javascripts/editor/view_mode';
import '../../../styles/layout/_editor';
import { bindCustomLinkPostEvent } from '../../../javascripts/custom_link_menu'

var PageLayoutTop = {
  requestInitPosts: function (initPostUrl) {
    $.ajax({
      type: 'GET',
      url: initPostUrl,
      dataType: 'script',
    }).done((response) => { });
  },
  initPostsByCallbackAndPostTagId: function (initPostUrl, postTagId) {
    let self = this;
    if (window.IntersectionObserver) {
      const options = {
        root: null,
        rootMargin: '100px',
        threshold: 0,
      };

      function handleIntersect(entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            self.requestInitPosts(initPostUrl);
            observer.unobserve(entry.target);
          }
        });
      }

      let observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(document.querySelector(postTagId));
    } else {
      self.requestInitPosts(initPostUrl);
    }
  },
  bindCustomLinkPostEvent: function(document) {
    bindCustomLinkPostEvent(document)
  },
};

if (!customElements.get('block-custom-html')) {
  customElements.define('block-custom-html', BlockCustomHtml);
}

if (!window?.Coorum) {
  window.Coorum = {};
}
window.Coorum.PageLayoutTop = PageLayoutTop;
window.Coorum.KeenSlider = KeenSlider;
window.Coorum.Like = Like;

$(() => {
  const viewMode = new ViewMode('oembed[url]');
  viewMode.replace();
});
